// Global Colors
$primary-900: #0F4B1B;
$primary-900-1: rgba(15, 75, 27, 0.1);
$primary-700: #246531;
$primary-600: #387F46;
$primary-400: #6BC57D;
$primary-200: #E2F6E6;
$primary-100: #F2FBF4;

$secondary-800: #C00404;  
$secondary-700: #D90000;
$secondary-100: #FFF0F0;

$neutral-100: #F7F4EE;
$neutral-50: #F9F7F3;
$white: #FFFFFF;
$white-01: rgba(255, 255, 255, 0.1);

// Global Font Sizes
$font-size-base: 1rem;
$line-height-base: 1.6;
