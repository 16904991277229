@import "./global-styles";
/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/static/Inter-Regular.ttf"),
    url("assets/fonts/Inter/static/Inter-Medium.ttf"),
    url("assets/fonts/Inter/static/Inter-SemiBold.ttf"),
    url("assets/fonts/Inter/static/Inter-Bold.ttf");
}
@font-face {
  font-family: "Sentient";
  src: url("assets/fonts/Sentient/Sentient-Medium.eot"),
    url("assets/fonts/Sentient/Sentient-Medium.ttf"),
    url("assets/fonts/Sentient/Sentient-Medium.woff"),
    url("assets/fonts/Sentient/Sentient-Medium.woff2");
}

.vh-min-100 {
  min-height: 100vh !important;
}

.pointer {
  &:hover {
    cursor: pointer !important;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
}

.visible-phone {
  display: none !important;
}

.visible-tablet {
  display: none !important;
}

.visible-desktop {
}

// Don't set initially
.hidden-phone {
}

.hidden-tablet {
}

.hidden-desktop {
  display: none !important;
}

.cancel-input {
  position: absolute;
  right: 6px;
  top: 15px;
  font-size: 18px;
  z-index: 9;
  opacity: 0.5;
}

.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ng-invalid.ng-touched,
app-date-input {
  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
  .date-control {
    border-right: none;
    padding-right: 0;
    flex: 0 0 135px !important;
    &:focus {
      box-shadow: none;
    }
  }
  .time-control {
    border-left: none;
    padding-left: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .date-time-group {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    position: relative;
    &:after {
      content: ",";
      display: block;
      position: absolute;
      top: 7px;
      left: 112px;
      z-index: 4;
    }
    &:focus-within {
      box-shadow: 0 0 0 0.15rem rgba(107, 105, 104, 0.45);
    }
  }
  input.ng-invalid.ng-touched {
    border-color: #fd4946;
  }
}
.input-container {
  position: relative;
  .form-control {
    color: transparent;
    border: 1px solid #dedede;
    &:focus,
    &.set {
      color: initial;
      & + label {
        transform: scale(0.8);
        padding: 1px 5px;
      }
    }
  }
  label {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 9px;
    padding-top: 14px;
    padding-left: 10px;
    z-index: 50;
    transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out;
    transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    -moz-transform-origin: 0 50%;
    &.set {
      transform: scale(0.8);
      padding: 1px 5px;
    }
  }
  .form-control:first-child,
  .form-control.first,
  .input-group-prepend:first-child > .input-group-text {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  .form-control:last-child,
  .form-control.last,
  .input-group-append:last-child > .input-group-text {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .form-control:not(.last) {
    // border-right: none !important;
  }
  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    color: transparent;
    .value-selected {
      color: initial;
    }
  }
  select {
    padding-right: 15px;
  }
  .form-control,
  select.form-control:not([size]):not([multiple]) {
    padding-top: 25px !important;
    height: 57px;
    border-radius: 8px;
  }
  .append .input-group-text {
    background: #fff;
    border: 1px solid #ededed;
  }
}
.error {
  min-height: 25px;
  line-height: 20px;
  color: #fd4946;
  padding: 5px 0 0px 7px;
  font-size: 14px;
  p {
    margin: 0;
  }
}
.popover.bs-popover-bottom .arrow {
  top: 2px;
}
.btn-super-admin {
  color: #fff;
  background: $primary-900;
  &:hover {
    color: #fff;
  }
}
.btn-corporate-admin {
  color: #fff;
  background: $primary-600;
  &:hover {
    color: #fff;
  }
}
.btn-location-admin {
  background: $primary-400;
}
.btn-customer {
  &:hover {
  }
}

// bs modal styles
.modal-body {
  white-space: pre-wrap;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
}
.modal-header {
  justify-content: flex-end;
  padding-bottom: 10px;
  button {
    padding: 0 !important;
  }
}
.modal-header,
.modal-body,
.modal-footer {
  background: #f9f7f2;
  border: none;
}
.modal-content {
  border-radius: 15px;
  overflow: hidden;
}

.btn-has-not-arrived {
  color: #fff;
  background: #104b1b;
  &:hover {
    color: #fff;
  }
}
.btn-waiting {
  color: #fff;
  background: $primary-600;
  &:hover {
    color: #fff;
  }
}
.btn-default {
  border: 2px solid #387f46;
}
.bs-datepicker {
  box-shadow: none;
  border-radius: 1rem !important;

  .bs-datepicker-body {
    min-width: 290px;
  }
  button {
    padding: 0 0.6rem !important;
  }
}
.bs-datepicker-body {
  border: none;
  table {
    td {
      color: #387f46 !important;
      span {
        &.selected {
          background: #387f46 !important;
        }
        &.today {
          border: 1px solid #387f46;
        }
      }
    }
  }
}
pagination {
  .page-link {
    color: $primary-700;
    border: 1px solid $primary-400;
    &:hover {
      background-color: $primary-200;
      border-color: $primary-400;
      color: $primary-700;
    }
    &:focus {
      background-color: $primary-100;
      border-color: $primary-600;
      color: $primary-700;
    }
  }
  .page-item {
    &.disabled {
      .page-link {
        border: 1px solid $primary-400;
      }
    }
    &.active {
      .page-link {
        border-color: $primary-700;
        background: $primary-600;
      }
    }
  }
}
.bs-datepicker-head {
  button {
    color: #000 !important;
  }
  background-color: #fff !important;
}

// Phones only
@media (max-width: 767px) {
  // Show
  .visible-phone {
    display: inherit !important;
  } // Use inherit to restore previous behavior
  // Hide
  .hidden-phone {
    display: none !important;
  } // Hide everything else
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .order-first-xs {
    order: -1;
  }
}

.hidden {
  opacity: 0;
  position: absolute;
  left: 99999999;
  top: 99999999;
  pointer-events: none;
  height: 0;
  width: 0;
}
.input-container input.hidden {
  height: 0 !important;
  width: 100% !important;
  padding: 0 !important;
  bottom: 0;
  left: 0;
}
.input-group-text {
  background: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style-iw-tc:after {
  background: transparent !important;
}

.dropdown-item.active {
  background: $primary-900;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary-700 !important;
}

.required {
  position: relative;
  color: $secondary-800;
  padding-left: 2px;
}

pagination {
  .page-item.active {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 22px;
      height: 22px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 2px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      z-index: 10;
      transition: all 0.5s ease-in-out;
      will-change: opacity;
      animation: button-loading-spinner 0.7s linear infinite;
    }
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
.loading {
  pagination {
    .page-item.active {
      &:after {
        opacity: 1;
      }
    }
  }
}

// table column flagged styles
.bg-deleted {
  background: #fff;
  filter: contrast(0.5) brightness(1.2);
  &:hover {
    background: #ccc;
    filter: contrast(1);
  }
}

// select disabled styles
select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
  border: none;
  background: none !important;
  &::-ms-expand {
    display: none;
  }
}

// Tablets & small desktops only
@media (min-width: 768px) and (max-width: 979px) {
  // Show
  .visible-tablet {
    display: inherit !important;
  } // Hide
  .hidden-tablet {
    display: none !important;
  } // Hide everything else
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transition: 2s ease-in-out;
  }
}
