// Required to utilize variants
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import "./global-theme";
// @import url('https://fonts.googleapis.com/css?family=Inter:400,500,600,700');

$grey: #e6e9ee;
$dark-grey: #4c4c4c;
$hms-grey: #0f4b1b25;

$blue: #0e7aff;
$green: #69ffb9;
$orange: #ffaa01;
$yellow: #ffd787;

$secondary: $blue;

$bg: $neutral-50;
$navBg: $white;
$navText: $primary-900;
$lightText: #ffffff;
$lightBg: #ffffff;

body {
  background-color: $bg;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: $font-size-base;
  line-height: 1.6;
  color: $primary-900;
  @media (max-width: 576px) {
    // background-color: $lightBg!important;
  }
}
.card {
  border: 0 !important;
  border-radius: 1rem !important;
  overflow: hidden;
}
.hms-card-border {
  border: 1px solid rgba(15, 75, 27, 0.1) !important;
}
.bg-primary {
  background-color: $secondary-700 !important;
}

.bg-secondary {
  background-color: $primary-700 !important;
}
.bg-neutral-50 {
  background-color: $bg;
}

.hms-badge {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 0.75rem !important;
  padding: 0.25rem 0.5rem !important;
  background: $primary-100 !important;
  color: $primary-900 !important;
}

.btn-transparent {
  border: 1px solid $primary-900;
  color: $primary-900;
  background: transparent;
}

.text-primary {
  color: $primary-900 !important;
  &:hover {
    color: $primary-700 !important;
  }
}

.text-secondary {
  color: $secondary-800 !important;
  &:hover {
    color: $secondary-700 !important;
  }
}

.count-input-grp {
  display: flex;
  flex-direction: row;
  border: 0.5px solid $primary-900;
  border-radius: 10px;
  max-width: 125px;
  background-color: $white;
  button {
    padding: 3px 10px !important;
    background-color: #fff;
    border: 0;
    width: 30%;

    &:hover {
      background-color: $primary-900;
      color: #fff;
    }
  }
  .minus-btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .plus-btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  input {
    height: 30px;
    width: 40%;
    padding: 5px;
    border-left: 0.5px solid $primary-900;
    border-right: 0.5px solid $primary-900;
    background-color: #fff !important;
    border-top: 0;
    border-bottom: 0;
    border-radius: 0;
  }
}

button:disabled {
  cursor: not-allowed;
}
button {
  font-size: 0.75rem !important;
  letter-spacing: 2px !important;
  text-transform: uppercase !important;
  padding: 0.8rem !important;
  border-radius: 10px !important;
}
.rounded-xl {
  border-radius: 0.6rem;
}
.bg-neutral-100 {
  background-color: $neutral-100;
}

.btn-primary {
  @include button-variant($secondary-700, darken($secondary-700, 7.5%));
}

.btn-secondary {
  @include button-variant($primary-900, darken($primary-900, 7.5%));
  color: $lightText;
}

.btn-outline-secondary {
  @include button-outline-variant($primary-900);

  &:hover {
    color: $lightText;
    background-color: $primary-900;
    border-color: darken($primary-900, 7.5%);
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 0.2rem rgba($primary-900, 0.5);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $lightText;
    background-color: darken($primary-900, 7.5%);
    border-color: darken($primary-900, 10%);
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary-900, 0.5);
  }
}

.hms-radius {
  border-radius: 10px !important;
}
.hms-right-radius {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.hms-left-radius {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

h1 {
  font-family: "p22-mackinac-pro", "Sentient", serif; //update to P22
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.3;
}

h2 {
  font-family: "p22-mackinac-pro", "Sentient", serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.3;
}

h3 {
  font-family: "p22-mackinac-pro", "Sentient", serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.3;
}

h4 {
  font-family: "p22-mackinac-pro", "Sentient", serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3;
}

h5 {
  font-family: "Inter", sans-serif;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.3;
}

small {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.6;
}

.main {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @media (min-width: 769px) {
    padding-left: 1.7rem !important;
    padding-right: 1.7rem !important;
  }
}

.padding-15-0 {
  padding: 15px 0;
}

.margin-30-0 {
  margin: 30px 0;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-20-0 {
  margin: 20px 0;
}

.view-photo {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .view-container {
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 20px;
    a {
      font-weight: 600;
      height: 30px;
      display: block;
      position: absolute;
      right: 15px;
      text-transform: uppercase;
      background: red;
      color: #fff;
      padding: 3px 5px;
      border-radius: 4px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      z-index: 1000;
    }
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

.full-width {
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.wizard-title {
  display: none;
  @include for-phone-only {
    display: block;
    text-align: center;
    font-size: 1.65em;
  }
}

.direction-btn-wrapper {
  padding: auto 16px;
}

@media (max-width: 700px) {
  .small-hide {
    display: none !important;
  }
  .small-show {
    display: block !important;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
